import { Alert, Backdrop, Box, CircularProgress, Snackbar, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const CommonFooterComponents = ({
    successMessage="", showSuccessMessage=false,
    errorMessage="", showErrorMessage=false,
    showBackDrop=false, handleCloseBackdrop,
    handleCloseAlert
}) => {

    const { t } = useTranslation();

    return (
        <Box>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={showSuccessMessage} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity="success" variant="filled">
                        {t(successMessage)}
                    </Alert>
                </Snackbar>
                <Snackbar open={showErrorMessage} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity="error" variant="filled">
                        {t(errorMessage)}
                    </Alert>
                </Snackbar>
            </Stack>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackDrop}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default CommonFooterComponents;