import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const CardSectionHeader = ({title}) => {

    const { t } = useTranslation();
    
    return (
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t(title)}
        </Typography>
    )
}

export default CardSectionHeader;