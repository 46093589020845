import { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router";

export const ModuleContext = createContext({});

export const ModuleContextProvider = ({children}) => {

    // Context to keep the current page and filters when drilling down the
    // bottom of the react tree and then returning to the list.
    const [listFilters, setListFilters] = useState(null);
    const [listPage, setListPage] = useState(1);
    const [moduleName, setModuleName] = useState(null);
    const [isLoadingList, setIsLoadingList] = useState(false);
    // Indicates if a search has already been performed
    const [hasSearch, setHasSearch] = useState(false);
    // Context to hold the main object within a module (Eg. a Producer, a Contract)
    const mainObject = useState(null);

    const setModule = (name) => {
        if(name !== moduleName) {
            setModuleName(name);
            resetListState();
        }
    }

    const resetListState = () => {
        setListFilters([]);
        setListPage(1);
        setIsLoadingList(false);
        setHasSearch(false);
    }
    const moduleContext = {
        listFilters, setListFilters,
        listPage, setListPage, mainObject,
        moduleName, setModule,
        isLoadingList, setIsLoadingList,
        resetListState:resetListState,
        hasSearch, setHasSearch
    };

    useEffect(() => {
        return () => resetListState();
    }, [])

    return (
        <ModuleContext.Provider value={moduleContext}>
            <Outlet />
        </ModuleContext.Provider>
    )
}