import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AuthProvider } from "contexts/JWTAuth";
import "nprogress/nprogress.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import React, { StrictMode } from "react";
import "react-image-lightbox/style.css";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter } from "react-router-dom";
import 'simplebar-react/dist/simplebar.min.css';
import App from "./App";
import SettingsProvider from "./contexts/settingsContext";
import * as ReactDOMClient from 'react-dom/client';
import { useTranslation } from "react-i18next";

const LocalizationIndieProvider = (props) => {
    const {i18n} = useTranslation();
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
            {props.children}
        </LocalizationProvider>
    );
}

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
    <StrictMode> 
        <LocalizationIndieProvider>
                <SettingsProvider>
                    <BrowserRouter>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </BrowserRouter>
                </SettingsProvider>
        </LocalizationIndieProvider>
    </StrictMode>
);