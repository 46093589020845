import { Small } from "components/Typography";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import FlexVerticalAlignCenter from "components/flexbox/FlexVerticalAlignCenter";
import CardSectionHeader from "components/typography/CardSectionHeader";
import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import useAuth from "hooks/useAuth";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { validateFiscalNumber } from "utils/utils";
import * as Yup from "yup";
import { DEFAULT_PAGE_TITLE } from "../../constants";

const { LoadingButton } = require("@mui/lab");
const { Container, Paper, Stack, Box, Button, Grid, Card, CardContent, Link, useMediaQuery } = require("@mui/material");
const { default: IndieButton } = require("components/forms/IndieButton");
const { default: IndieTextField } = require("components/forms/IndieTextField");
const { Formik, Form } = require("formik");
const { useState, useContext, useEffect } = require("react");
const { useTranslation } = require("react-i18next");

const MobileLogin = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);
    const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200));
    const gridSize = downMd ? "12" : "6";

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .matches(/^\d{9}$/, 'Número Fiscal deve ter 9 digitos.')
            .test('valid-nif', 'Número Fiscal inválido', (value) => {
                return validateFiscalNumber(value);
            }),
        password: Yup.string()
            .required(t("error.forms.mandatory")),
    });

    const submitLogin = (values) => {
        setLoading(true);
        auth.login(values.username, values.password)
            .then(() => {
                setAndShowSuccessMessage(t("message.form.login.sucess"));
                setLoading(false);
                navigate("/");
            })
            .catch((error) => {
                console.error(error);
                setAndShowErrorMessage(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        document.title = DEFAULT_PAGE_TITLE;
    }, []);

    return (
        <FlexVerticalAlignCenter>
            <FlexRowAlign>
                <Card component={Paper} sx={{ width: downMd ? "100%" : "80%", p: downMd ? 1: 5 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            {downMd && <Grid item xs={gridSize} style={{textAlign: "center"}}>
                                <img src="/static/logo/ie-logo-horizontal.png" height="100px" alt="Indie Energy" />
                            </Grid>}
                            <Grid item xs={gridSize} style={{textAlign: "center"}}>
                                <FlexRowAlign>
                                    <Formik
                                        initialValues={{
                                            username: "",
                                            password: "",
                                            submit: null,
                                            remember: true,
                                        }}

                                        validationSchema={validationSchema}
                                        onSubmit={submitLogin}
                                    >
                                        {(formik) => (
                                            <Form>
                                                <Stack spacing={2}>
                                                    <CardSectionHeader title={t("label.title.loginForm")} />
                                                    <IndieTextField
                                                        id="username"
                                                        name="username"
                                                        label={t("label.form.username")}
                                                    />

                                                    <IndieTextField
                                                        id="password"
                                                        name="password"
                                                        type="password"
                                                        label={t("label.form.password")}
                                                    />

                                                    {loading ? (
                                                        <LoadingButton loading fullWidth variant="contained">
                                                            {t("label.button.signin")}
                                                        </LoadingButton>
                                                    ) : (
                                                        <Button fullWidth type="submit" variant="contained">
                                                            {t("label.button.signin")}
                                                        </Button>
                                                    )}

                                                    <IndieButton onClick={() => navigate("/login/agent")} variant="outlined" color="primary">Entrar como Agente</IndieButton>
                                                </Stack>
                                                <Stack spacing={2} sx={{ pt: 4 }}>
                                                    <Link component={RouterLink} to="/user/forget-password/request"> <Small color="secondary.red">{t("label.form.forgotPassword")}</Small> </Link>
                                                    <Link component={RouterLink} to="/user/register/request"><Small>Ainda não se registou? Peça uma nova chave de registo aqui</Small></Link>
                                                    <Link href="mailto:suporte@indie-energy.com" target="_blank"><Small>Problemas? Contacte o nosso suporte.</Small></Link>
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                </FlexRowAlign>
                            </Grid>
                            {!downMd && <Grid item xs={gridSize} textAlign="center">
                                <FlexRowAlign sx={{ height: "100%" }}>
                                    <img src="/static/logo/ie-logo-horizontal.png" height="150px" alt="Indie Energy" />
                                </FlexRowAlign>
                            </Grid>}
                        </Grid>
                    </CardContent>
                </Card>
            </FlexRowAlign>
        </FlexVerticalAlignCenter>
    )
}

export default MobileLogin;