const { Button } = require("@mui/material");
const { default: useAuth } = require("hooks/useAuth");

const IndieButton = ({permissionGroups, ...params}) => {

    const { hasPermissionGroup } = useAuth();

    if(permissionGroups && ! hasPermissionGroup(permissionGroups)) {
        return "";
    }

    return (
        <Button {...params}></Button>
    )
}

export default IndieButton;