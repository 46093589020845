import { TextField } from "@mui/material";
import { useField } from "formik";
import useAuth from "hooks/useAuth";

const IndieTextField = ({ label, maxLength, permissionGroups, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { hasPermissionGroup } = useAuth();

  if(permissionGroups && ! hasPermissionGroup(permissionGroups)) {
      return "";
  }

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      onChange={(e) => helpers.setValue(e.target.value)}
      error={meta.touched && meta.error}
      helperText={meta.touched && meta.error}
      variant="standard"
      inputProps={{ maxLength: maxLength ? maxLength : 50 }}
    />
  );
}

export default IndieTextField;