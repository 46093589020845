import i18next from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
    en: {
        translation: {
            CRM: "CRM",
            Shop: "Shop",
            Chat: "Chat",
            Sales: "Sales",
            Results: "Results",
            Invoice: "Invoice",
            Payment: "Payment",
            Profile: "Profile",
            Account: "Account",
            Pricing: "Pricing",
            Checkout: "Checkout",
            Sessions: "Sessions",
            Ecommerce: "Ecommerce",
            Dashboards: "Dashboards",
            "Enrolled Courses": "Enrolled Courses",
            "Course Completed": "Course Completed",
            "Course in Progress": "Course in Progress",
            "Learning Management": "Learning Management",
            "Welcome Back! Watson": "Welcome Back! Watson",
            "Job Management": "Job Management",
            "User & Contact": "User & Contact",
            "User List": "User List",
            "User Grid": "User Grid",
            "Contact List": "Contact List",
            "Contact Grid": "Contact Grid",
            "Invoice List": "Invoice List",
            "Invoice Details": "Invoice Details",
            "Create Invoice": "Create Invoice",
            "Product Details": "Product Details",
            "Billing Address": "Billing Address",
            "Payment Complete": "Payment Complete",
            "Admin Ecommerce": "Admin Ecommerce",
            "Product List": "Product List",
            "Product Grid": "Product Grid",
            "Create Product": "Create Product",
            "Upcoming Task": "Upcoming Task",
            "Study Time Last Week": "Study Time Last Week",
            "Your Downloads": "Your Downloads",
            "Course Status": "Course Status",
            "Prospecting-Communities": "Prospecting",
        },
    },
    es: {
        translation: {
            CRM: "CRM",
            Chat: "Chat",
            Shop: "Tienda",
            Sales: "Ventas",
            Payment: "Pago",
            Account: "Cuenta",
            Profile: "Perfil",
            Pricing: "Precios",
            Invoice: "Factura",
            Sessions: "Sesiones",
            Results: "Resultados",
            Checkout: "Verificar",
            Dashboards: "Cuadros de mando",
            Ecommerce: "Comercio electrónico",
            "Upcoming Task": "Próxima tarea",
            "Your Downloads": "Tus descargas",
            "Course Status": "Estado del curso",
            "Course in Progress": "Curso en curso",
            "Enrolled Courses": "Cursos inscritos",
            "Course Completed": "Curso completado",
            "Job Management": "Gestión de trabajos",
            "User & Contact": "Usuario y contacto",
            "User List": "Lista de usuarios",
            "User Grid": "Cuadrícula de usuario",
            "Contact List": "Lista de contactos",
            "Contact Grid": "Cuadrícula de contacto",
            "Invoice List": "Lista de facturas",
            "Create Invoice": "Crear factura",
            "Billing Address": "Dirección de Envio",
            "Payment Complete": "Pago completo",
            "Product List": "Lista de productos",
            "Create Product": "Crear producto",
            "Product Details": "Detalles de producto",
            "Product Grid": "Cuadrícula de productos",
            "Invoice Details": "Detalles de la factura",
            "Learning Management": "Gestión del aprendizaje",
            "Welcome Back! Watson": "¡Bienvenido de nuevo! Watson",
            "Admin Ecommerce": "Administrador de comercio electrónico",
            "Study Time Last Week": "Tiempo de estudio la semana pasada",
        },
    },
    pt: {
        translation: {
            // Menu
            "Energy Communities": "Comunidades de Energia",
            "Administration": "Administração",
            "Manage Entities": "Gestão de Entidades",
            "Manage Members": "Gestão de Membros",
            "Manage Delivery Points": "Gestão de Pontos de Entrega",
            "Member": "Membro",
            "Community of": "Comunidade de",
            "Energy": "Energia",
            "Surplus": "Excedente",
            "Potência": "Power",
            "Storage": "Armazenamento",
            "Produtores": "Prosumers",
            "Active Members": "Membros Ativos",
            "All": "Tudo",
            "Building": "Em Construção",
            "Prospecting-Communities": "Construção",
            "Add Community": "Nova Comunidade",
            "Communities": "Comunidades",
            "Reports": "Relatórios",
            "Forming": "Projeto",
            "Statistics": "Estatisticas",
            "Members": "Membros",
            "Account": "Conta",
            "All Members": "Todos",
            "Prosumers": "Produtores",
            "Consumers": "Consumidores",
            "Add New Member": "Novo Membro",
            "Financial": "Financeiro",
            "Logout": "Sair",
            "Delivery Points": "Pontos de Entrega",
            "Production Units": "Produção",
            "Installations": "Instalações",
            "Invoices": "Faturação",
            "Hi": "Olá",
            "Add": "Novo",
            "Consumption Instalations": "Consumo",
            "Storage Installations": "Armazenamento",
            // Membros

            "Name": "Nome",
            "Community": "Comunidade",
            "Zip Code": "Código Postal",
            "Status": "Estado",
            "Selling Price": "Preço de Venda",
            "Start Date": "Data de Início",
            "End Date": "Data de Fecho",
            "Network Buying Price": "Preço de Compra Rede",
            "Community Buying Price": "Preço de Compra Comunidade",
            "New Price": "Novo Preço",
            "Installation": "Instalação",
            "Invoicing Entities": "Entidades de Faturação",
            "Member Installation": "Dados da Instalação",
            "Consumtion Delivery Point": "CPE de Consumo",
            "Production Delivery Point": "CPE de Produção",
            "Storage Delivery Point": "CPE de Armazenamento",
            "Billing Period": "Período de Faturação",
            "City": "Cidade",
            "Fiscal Number": "Número de Contribuinte",
            "Company?": "Empresa?",
            // Comunidades
            "Overview": "Sumário",
            "View Community": "Ver Comunidade",
            "Community Details": "Detalhes",
            "Sales": "Vendas",
            "Documents": "Documentos",
            
            "Document List": "Documentos Carregados",
            "Production": "Produção",
            // Delivery Points
            "Consumption Installations": "Instalações de Consumo",
            "Address": "Morada",
            "Country": "País",
            "View Consumption Installation": "Ver Instalação de Consumo",
            "Add Consumption Installation": "Nova Instalação de Consumo",
            "Meter Type": "Tipo de Contador",
            "Capacity": "Capacidade",
            "Unit of Measure": "Unidade de Medida",
            //Layout
            "label.layout.greeting":"Olá",
            // Labels Page Titles
            "label.title.markets":"Mercado Diário / Intradiário",
            "label.title.paymentsAndReceiptsDetails":"Detalhes de Pagamentos e Recebimentos",
            "label.title.results":"Resultados",
            "label.title.receivable":"Pagamentos e Recebimentos",
            "label.title.energy":"Energia",
            "label.title.programmeResults":"Resultados dos Programas",
            "label.title.programmeDate":"Data do Programa",
            "label.title.intradayMarkets":"Mercados Intra-Diários",
            "label.title.reports":"Relatórios",
            "label.title.preRegistration":"Pré-Registo",
            "label.title.activation":"Em Ativação",
            "label.title.activeContracts":"Ativos",
            "label.title.refused":"Recusados",
            "label.title.closed":"Fechados",
            "label.title.issueDate":"Data de Emissão",
            "label.title.dataUpload":"Carregamento de Dados de Energia",
            "label.title.uploadDocuments":"Carregar Documentos",
            "label.title.dialog.actionLog":"Histórico de Ações",
            "label.title.dialog.documentUpload":"Carregar Documentos",
            "label.title.dialog.validationLog":"Validação de Contrato",
            "label.title.editFinancialDocument":"Editar Documento",
            "label.title.viewFinancialDocument":"Visualizar Documento",
            "label.title.notAddress":"Sem Morada",
            "label.title.noPlanSelected":"Sem Plano",
            "label.title.noProductSelected":"Sem Produto",
            "label.title.noExternalMeterId":"Código Meter Não Disponível",
            "label.title.noInstalledPower":"N/A",
            "label.title.termsAndConditions":"Termos e Condições de Utilização da Aplicação da Indie Energy",
            "label.title.authorizedUse":"Utilização da APlicação Indie Energy",
            "label.title.lawsAndLitigations":"Lei Aplicável e Resolução de Litígios",
            "label.title.conditionModifications":"Alteração das Condições",
            "label.title.personalDataProtection":"Proteção de Dados Pessoais",
            "label.title.clientObligations":"Obrigações do Cliente",
            "label.title.responsabilities":"Responsabilidade",
            "label.title.definitions":"Definições",
            "label.title.overview":"Visão Geral",
            "label.title.ownership":"Propriedade",
            "label.title.loginForm":"Início de Sessão",
            "label.title.registrationForm":"Formulário de Registo",
            "label.title.search":"Pesquisa",
            "label.title.offers":"Ofertas",
            "label.title.offerDetails":"Detalhes da Oferta",
            "label.title.offerSubmission":"Submissão de Ofertas",
            "label.title.offerView":"Consulta de Ofertas",
            "label.title.marketAgent":"Agente de Mercado",
            "label.title.marketProgramme":"Programa de Mercado",
            "label.title.contractTerms":"Termos do Contrato",
            "label.title.contractSigningandEndDates":"Assinatura / Cessação do Contrato",
            "label.title.newReportRequest":"Novo Pedido de Relatório",
            "label.title.newForecastTypeInstance":"Nova Previsão",
            "label.title.editForecastTypeInstance":"Modificar Previsão",
            "label.title.exportSurplusData": "Descarregar Documento Excedente",
            "label.title.description": "Descrição",
            "label.title.ggsDeviations":" Gestão Global de Sistema (GGS) - Desvios",
            "label.title.deviations": "Desvios",
            "label.title.referenceStartDate": "Data de Referência Inicio",
            "label.title.referenceEndDate": "Data de Referência Fim",
            "label.title.documentsUpload": "Carregar Documentos",
            "label.title.documentType":"Tipo de Documento",
            "label.title.documentHeader":"Cabeçalho do Documento",
            "label.title.documentDetails":"Detalhes do Documento",
            "label.title.productionUnits": "Unidades de Produção",
            "label.title.producerExists": "Produtor Existente",
            "label.title.viewProductionUnit": "Ver Unidade de Produção",
            "label.title.viewProcessDetails":"Ver Detalhes do Processo",
            "label.title.energyKWh":"Energia (kWh)",
            "label.title.entities": "Entidades",
            "label.title.newEntity": "Nova Entidade",
            "label.title.newFinancialDocument":"Novo Documento",
            "label.title.viewEntity": "Ver Entidade",
            "label.title.members": "Membros",
            "label.title.newMember": "Novo Membro",
            "label.title.viewMember": "Ver Membro",
            "label.title.contactInformation": "Dados de Contacto",
            "label.title.agentInformation": "Dados do Agente",
            "label.title.consumptionInstallation": "Instalação de Consumo",
            "label.title.technicalDetails": "Informação Técnica",
            "label.title.solarPanels": "Painéis Solares",
            "label.title.inverters": "Inversores",
            "label.title.installationLicencing":"Licenciamento da Instalação",
            "label.title.identity":"Identificação",
            "label.title.productionUnit": "Unidade de Produção (UPAC)",
            "label.title.registrationInformation": "Informação de Registo",
            "label.title.receivingConditions":"Dados e Condições de Recebimentos",
            "label.title.others": "Outras Informações",
            "label.title.invoicingInformation": "Dados de Faturação",
            "label.title.paymentInformation": "Dados para Recebimentos",
            "label.title.memberCommunity": "Dados da Comunidade",
            "label.title.viewInvoice": "Consultar Fatura",
            "label.title.invoiceInfo": "Dados de Fatura",
            "label.title.invoices": "Faturas",
            "label.title.due": "Vencidas",
            "label.title.unpaid": "Por Pagar",
            "label.title.unitValue":"Valor Unit.",
            "label.title.netTotal":"Valor Líquido",
            "label.title.taxes":"Impostos",
            "label.title.programming":"Programação",
            "label.title.discountPercentage":"Desconto",
            "label.title.discountValue":"Total com Desconto",
            "label.title.totalEnergy":"Total Energia Contratada",
            "label.title.totalAmmount":"Valor a Receber/Pagar",
            "label.title.averagePrice":"Preço Médio",
            "label.title.total":"Total",
            "label.title.all":"Todas",
            "label.title.upacOwner":"Proprietário da UPAC",
            "label.title.communities":"Comunidades",
            "label.title.communityOf":"Comunidade de",
            "label.title.newPrice":"Atualizar Preços",
            "label.title.producers":"Producers",
            "label.title.address":"Morada",
            "label.title.meter":"Meter",
            "label.title.contactInfo":"Dados de Contacto",
            "label.title.lastReceipt":"Último Recebimento",
            "label.title.goBackConfirmation":"Deseja mesmo voltar atrás?",
            "label.title.physicalUnitDetails":"Caracterização da Instalação",
            "label.title.commercialAgentData": "Dados do Agente Comercial",
            "label.title.fileName":"Nome do Ficheiro",
            "label.title.fileTitle":"Título",
            "label.title.fileType":"Tipo de Ficheiro",
            "label.title.forecastTypes":"Tipos de Previsão",
            "label.title.uploadFiles":"Carregar Documentos",
            "label.title.contractDocuments":"Lista de Documentos",
            "label.title.noPhysicalUnit":"Sem Instalação",
            "label.title.paid":"Liquidadas",
            "label.title.pending":"Pendentes",
            "label.title.processed":"Processadas",
            "label.title.viewAggregator": "Ver Agregador",
            "label.title.viewAgent": "Ver Agente de Mercado",
            "label.title.processingResult": "Resultado do Processamento de Faturas",
            "label.title.agentCodes":"Códigos REN / OMIE",
            "label.title.omieCertificate":"Certificado OMIE",
            "label.title.certificatePassword":"Password Certificado OMIE",
            "label.title.marketPhysicalUnits":"Unidades Físicas",
            "label.title.newPhysicalUnit":"Nova Unidade Física",
            "label.title.newOfferingUnit":"Nova Unidade de Oferta",
            "label.title.viewMarketUnit": "Ver Unidade Física",
            "label.title.installations":"Instalações",
            "label.title.details": "Dados",
            "label.title.viewInstallation": "Ver Instalação",
            "label.title.production": "Produção",
            "label.title.viewForecastType": "Ver Tipo de Previsão",
            "label.title.name":"Nome",
            "label.title.required":"Obrigatório?",
            "label.title.value":"Valor",
            "label.title.userProfile":"Dados de Acesso",
            "label.title.fiscalNumber":"NIF",
            "label.title.erpId":"Referência ERP",
            "label.title.default":"Usar por Omissão",
            "label.title.active":"Ativo",
            "label.title.fiscalEntities":"Entidades Fiscais",
            "label.title.notZipCode":"Sem Código Postal",
            // Labels Buttons
            "label.button.downloadExcel":"Download Excel",
            "label.button.cancelOffer":"Anular Oferta",
            "label.button.submitOffer":"Submeter Oferta",
            "label.button.createOffer":"Criar Oferta",
            "label.button.confirm":"Confirmar",
            "label.button.delete":"Apagar",
            "label.button.newReportRequest":"Novo Pedido de Relatório",
            "label.button.actionLog":"Histórico de Ações",
            "label.button.actions":"Outras Ações",
            "label.button.changeSignEndContract":"Alterar Assinatura / Cessação",
            "label.button.closeContract":"Fechar",
            "label.button.reopenContract":"Reabrir",
            "label.button.updateContractTerms":"Alterar Termos",
            "label.button.newPurchaseDocument":"Novo Documento de Compra / Autofatura",
            "label.button.newAutoInvoice":"Nova Autofatura",
            "label.button.saveNewPassword":"Guardar a nova Password",
            "label.button.recoverPassword":"Enviar Email de Recuperação de Password",
            "label.button.recoverRegistration":"Enviar Email de Recuperação de Registo",
            "label.button.agreeTermsAndConditions":"Confirmo que Li e Aceito os Termos e Condições",
            "label.button.processForecasts":"Processar Previsões",
            "label.button.newLocation":"Nova Localização",
            "label.button.process": "Processar",
            "label.button.back": "Voltar",
            "label.button.save": "Guardar",
            "label.button.savePassword":"Guardar Password",
            "label.button.stay": "Ficar",
            "label.button.doNotStay": "Prosseguir",
            "label.button.update": "Atualizar",
            "label.button.upload":"Carregar Documento",
            "label.button.remove": "Remover",
            "label.button.search": "Procurar",
            "label.button.previous": "Antes",
            "label.button.procuration": "Procuração",
            "label.button.next": "Seguinte",
            "label.button.newProductionUnit": "Nova Unidade de Produção",
            "label.button.newLine": "Nova Linha",
            "label.button.newEntity": "Nova Entidade",
            "label.button.newMember": "Novo Membro",
            "label.button.newProducer": "Novo Produtor",
            "label.button.newContract": "Novo Contrato",
            "label.button.approve": "Aprovar",
            "label.button.reprocess": "Reprocessar",
            "label.button.cancel": "Cancelar",
            "label.button.export": "Exportar",
            "label.button.newInvoice": "Novo Documento",
            "label.button.new": "Novo",
            "label.button.signin": "Iniciar Sessão",
            "label.button.register": "Registar",
            "label.button.viewProducer" : "Ver Produtor",
            "label.button.edit" : "Editar",
            "label.button.expCertificate":"Lic. Exploração",
            "label.button.viewDetails":"Ver Detalhes",
            "label.button.validateActivation":"Validar Ativação de Contrato",
            "label.button.contract":"Ver Contrato",
            "label.button.contracts":"Contratos",
            "label.button.download":"Descarregar",
            "label.button.newAggregator":"Novo Agregador",
            "label.button.close":"Fechar",
            "label.button.createNewB021PProcess":"Novo Processo de Registo na REN",
            "label.button.saveInvoicingData":"Atualizar Dados de Faturação",
            "label.button.createPhysicalUnit": "Criar Unidade Física",
            "label.button.createOfferingUnit": "Criar Unidade de Oferta",
            "label.button.copy":"Copiar",
            // Labels Forms
            "label.form.marketAgentCompanies":"Empresa",
            "label.form.contractPowerFee":"Fee de Potência (€/MW/Dia)",
            "label.form.contracts":"Contratos",
            "label.form.id":"ID",
            "label.form.uploadedBy":"Carregado por",
            "label.form.uploadedDate":"Data de Carregamento",
            "label.form.renewContract":"Renovar Contrato",
            "label.form.code":"Código",
            "label.form.fiscalEntity":"Entidade Fiscal de Faturação",
            "label.form.marketSession":"Programa",
            "label.form.programmeDate":"Data do Programa",
            "label.form.siteid":"Site ID",
            "label.form.maxInstances":"Número Máximo de Instâncias",
            "label.form.processingResult":"Resultado",
            "label.form.singleService": "Ato Único",
            "label.form.autoinvoicing":"Autofaturação",
            "label.form.atcud":"ATCUD",
            "label.form.hasOpenActivity":"Atividade Aberta",
            "label.form.hasNegativePrices":"Tem Preços Negativos?",
            "label.form.contractPriceDiscount":"Desconto Comercial (%)",
            "label.form.quarterHour":"Quarto-Horário",
            "label.form.hourly":"Horário",
            "label.form.energy":"Energia",
            "label.form.endDate":"Data Fim",
            "label.form.selectValue": "Selecionar um valor da lista.",
            "label.form.sessionNumber":"Número da Sessão",
            "label.form.programmeStartDate":"Data Início Horizonte",
            "label.form.programmeEndDate":"Data Fim Horizonte",
            "label.form.hourlyOption": "Opção Horária",
            "label.form.certifiedPower": "Potência Certificada",
            "label.form.estAnnualConsumption": "Consumo Anual Estimado",
            "label.form.title":"Título",
            "label.form.tensionLevel": "Nível Tensão",
            "label.form.deliveryPoint": "CPE",
            "label.form.zipCode": "Código Postal",
            "label.form.country": "País",
            "label.form.regulatorReferenceNumber": "Número de registo na DGEG",
            "label.form.registrationNumber":"Nº de Registo",
            "label.form.recordNumber":"Nº de Cadastro",
            "label.form.recordReferenceNumber": "Número de cadastro",
            "label.form.referenceDate":"Data de Referência",
            "label.form.referenceStartDate":"Data Início Período Faturação",
            "label.form.referenceEndDate":"Data Fim Período Faturação",
            "label.form.productionCompanyName": "Denominação do Produtor",
            "label.form.productionDeliveryPoint": "CPE de Produção",
            "label.form.productionType": "Tipo de Produção",
            "label.form.processCode":"Código de Processo",
            "label.form.oldSignDate":"Data de Assinatura Atual",
            "label.form.oldActDate":"Data de Ativação Atual",
            "label.form.newSignDate":"Nova Data de Assinatura",
            "label.form.newActDate":"Nova Data de Ativação",
            "label.form.newEndDate":"Nova Data de Fim",
            "label.form.newTermDate":"Nova Data de Terminação",
            "label.form.omieSolicitude" : "Código Solicitude OMIE",
            "label.form.consumptionDeliveryPoint": "CPE de Consumo",
            "label.form.injectsEnergy": "Injeta Energia na Rede?",
            "label.form.installationType": "Tipo de Instalação",
            "label.form.installedPowerKWp": "Potência Instalada (kWp)",
            "label.form.installedPowerKWh": "Potência Instalada (kW)",
            "label.form.installedPowerKva": "Potência Instalada (kVA)",
            "label.form.primaryRenewableSource": "Fonte Primária Renovável",
            "label.form.technologyType": "Tipo de tecnologia utilizada",
            "label.form.systemNominalPower": "Potência nominal do sistema FV(kWp)",
            "label.form.numberOfPanels": "Número de Painéis",
            "label.form.panelPower": "Potência dos Painéis (Wp)",
            "label.form.panelManufacturer": "Fabricante dos Painéis",
            "label.form.panelModel": "Modelo dos Painéis",
            "label.form.connectedPowerKWh": "Potência de ligação (kW)",
            "label.form.injectionPower": "Potência de injeção na RESP (kW)",
            "label.form.connectedPowerKva": "Potência de ligação (kVA)",
            "label.form.generatorPowerKWh": "Potência do Gerador (kW)",
            "label.form.numberOfInverters": "Número de Inversores",
            "label.form.invertersPower": "Potência dos inversores (kW)",
            "label.form.inverterManufacturer": "Fabricante do(s) inversor(es)",
            "label.form.inverterModel": "Modelo do inversor",
            "label.form.connectedToNetwork": "Instalação ligada à RESP ?",
            "label.form.expStartDate": "Data de entrada em exploração",
            "label.form.surfaceAreaAvailable": "Área da superfície disponível",
            "label.form.surfaceAreaCovered": "Área da superfície coberta pela UPAC",
            "label.form.surfaceType": "Tipo de superfície",
            "label.form.phone": "Número de Telefone",
            "label.form.short.phone": "Telefone",
            "label.form.physicalUnitType": "Tipo de Instalação",
            "label.form.email": "Email",
            "label.form.name": "Nome",
            "label.form.address": "Morada",
            "label.form.aggregator": "Agregador",
            "label.form.city": "Cidade",
            "label.form.fiscalNumber": "Número Fiscal",
            "label.form.isClient": "É Cliente?",
            "label.form.isAgent": "É Agente?",
            "label.form.isManagingEntity": "É EGAC?",
            "label.form.isSupplier": "É Fornecedor?",
            "label.form.erpSupplierID": "ID Fornecedor ERP",
            "label.form.erpCompanyID": "ID Empresa ERP",
            "label.form.erpClientID": "ID Cliente ERP",
            "label.form.community": "Comunidade",
            "label.form.status": "Situação",
            "label.form.add": "Criar",
            "label.form.active": "Ativo",
            "label.form.startDate": "Data de Início",
            "label.form.initialCommunityBuyingPrice": "Preço Inicial de Compra à Comunidade",
            "label.form.initialNetworkBuyingPrice": "Preço Inicial de Compra à Rede",
            "label.form.initialSellingPrice": "Preço Inicial de Venda",
            "label.form.billingPeriod": "Período de Faturação",
            "label.form.invoiceNumber": "# Fatura",
            "label.form.orderDate": "Data da Fatura",
            "label.form.billTo": "Fatura para",
            "label.form.billFrom": "Fatura de",
            "label.form.itemName": "Artigo",
            "label.form.itemQuantity": "Quantidade",
            "label.form.unitPrice": "Preço Unitário",
            "label.form.useProducerData": "Usar Dados de Produtor",
            "label.form.price": "Preço",
            "label.form.activeEnergy": "Energia Ativa",
            "label.form.amount": "Valor",
            "label.form.total": "Total",
            "label.form.nextInvoiceDate": "Data da Próxima Fatura",
            "label.form.currencyCode": "Moeda",
            "label.form.power": "Potência Instalada",
            "label.form.storage": "Armazenamento",
            "label.form.prosumers": "Produtores",
            "label.form.consumers": "Consumidores",
            "label.form.findCommunity": "Procurar Comunidade",
            "label.form.invoiceIBAN": "IBAN Faturação",
            "label.form.paymentIBAN": "IBAN Recebimentos",
            "label.form.paymentMethod": "Método de Pagamento",
            "label.form.paymentTerms": "Termos de Pagamento",
            "label.form.cae": "CAE",
            "label.form.username": "Utilizador",
            "label.form.password": "Palavra-Passe",
            "label.form.rememberMe": "Guardar Acesso",
            "label.form.forgotPassword": "Não sabe a Palavra-Passe? Peça uma nova aqui",
            "label.form.eInvoiceEmail": "Email Fatura Eletrónica",
            "label.form.upload": "Carregar Documentos",
            "label.form.networkPrice": "Energia na RESP",
            "label.form.selfConsPrice": "Autoconsumo",
            "label.form.sellingPrice": "Venda Excedente",
            "label.form.locality": "Localidade",
            "label.form.district": "Distrito",
            "label.form.longitude":"Longitude (°)",
            "label.form.latitude":"Latitude (°)",
            "label.form.description": "Descrição",
            "label.form.documentType": "Tipo de Documento",
            "label.form.documentNumber": "Código Documento",
            "label.form.doorNumber": "Porta",
            "label.form.apartmentNumber": "Apartamento",
            "label.form.stay": "Ficar",
            "label.form.goToProducer": "Ir Para Produtor",
            "label.form.gcsCode": "Código da Unidade Física",
            "label.form.product": "Produto",
            "label.form.plan": "Plano",
            "label.form.planCommission" : "Fee de Gestão (€/MWh)",
            "label.form.fixPlanPrice": "Preço (€/MWh)",
            "label.form.indexPlanPrcntg": "Fee de Gestão (%)",
            "label.form.signatureDate": "Data de Assinatura",
            "label.form.isAutoTermination": "Cessação de contrato na data de fim de contrato?",
            "label.form.contractTermntLeadDays" : "Rescisão Contratual",
            "label.form.contractDuration" : "Duração do Contrato (Meses)",
            "label.form.contractTermntDate" : "Data de Denúncia do Contrato",
            "label.form.contractActDate" : "Data de Ativação do Contrato",
            "label.form.contractEndDate": "Data de Fim do Contrato",
            "label.form.freeTaxes": "Isento de IVA",
            "label.form.clearedFinances": "Finanças Regularizadas",
            "label.form.leadDays": "Dias",
            "label.form.internalSalesAgent": "Nome do Agente Comercial Interno",
            "label.form.externalPartnerName" : "Empresa Parceira",
            "label.form.producer": "Produtor",
            "label.form.activationDate": "Data de Ativação",
            "label.form.meter": "Meter",
            "label.form.marketAgent": "Agente de Mercado",
            "label.form.marketOfferingUnit": "Unidade de Oferta",
            "label.form.isAggregation": "É Agregação?",
            "label.form.daily":"Diário",
            "label.form.dateFrom":"De",
            "label.form.dateTo":"A",
            "label.form.weekly":"Semanal",
            "label.form.monthly":"Mensal",
            "label.form.yearly":"Anual",
            "label.form.viewBy":"Ver Por",
            "label.form.yes":"Sim",
            "label.form.no":"Não",
            "label.form.all":"Todos",
            "label.form.county":"Concelho",
            "label.form.parish":"Freguesia",
            "label.form.newPassword":"Nova Palavra Passe",
            "label.form.repeatNewPassword":"Repetir Palavra Passe",
            "label.form.forecastTypeInstance":"Previsão",
            "label.form.taxRegime":"Regime Fiscal",
            "label.uom.kw":"kW",
            "label.uom.mw":"MW",
            "label.uom.kwh":"kWh",
            "label.uom.mwh":"MWh",
            // Labels Side Menus
            "label.menu.settlements":"Liquidações",
            "label.menu.dataUpload":"Carregamento de Dados",
            "label.menu.userDetails":"Perfil de Utilizador",
            "label.menu.invoicingDetails":"Informação de Recebimentos",
            "label.menu.voidDocument":"Anular Documento",
            "label.menu.newDocument":"Novo Documento Financeiro",
            "label.menu.marketAgent":"Agente de Mercado",
            "label.menu.viewContract":"Ver Contrato",
            "label.menu.clientArea":"Contratos",
            "label.menu.energyMarkets":"Mercados",
            "label.menu.dailyMarkets":"Mercado Diário",
            "label.menu.intraDayMarkets":"Mercado Intra-Diário",
            "label.menu.attributes":"Atributos",
            "label.menu.powerTrading":"Compra de Energia",
            "label.menu.locations":"Localizações",
            "label.menu.viewClient":"Ver Cliente",
            "label.menu.invoicingClients":"Clientes de Faturação",
            "label.menu.details": "Detalhes",
            "label.menu.addressInfo": "Detalhes da Morada",
            "label.menu.addCommunity": "Nova Comunidade",
            "label.menu.aggregator": "Agregador",
            "label.menu.techInformation": "Informação Técnica",
            "label.menu.productionUnitDetails": "Detalhes",
            "label.menu.registrationInfo": "Dados de Registo",
            "label.menu.solarPanels": "Painéis Solares",
            "label.menu.inverters": "Inversores",
            "label.menu.identity": "Identificação",
            "label.menu.others": "Outros",
            "label.menu.profile": "Perfil",
            "label.menu.additional": "Outros",
            "label.menu.consumptionInstallation": "Instalação de Consumo",
            "label.menu.communities": "Comunidades",
            "label.menu.viewCommunity" : "Ver Comunidade",
            "label.menu.productionUnit": "Unidade de Produção",
            "label.menu.storageUnit": "Unidade de Armazenamento",
            "label.menu.invoicing": "Faturação",
            "label.menu.production": "Produção",
            "label.menu.consumption": "Consumo",
            "label.menu.sales": "Faturação",
            "label.menu.documents": "Documentos",
            "label.menu.members": "Membros",
            "label.menu.invoices": "Faturas",
            "label.menu.kpis": "Indicadores",
            "label.menu.payments": "Pagamentos",
            "label.menu.networkAccess": "Acesso à Rede",
            "label.menu.surplus": "Excedente",
            "label.menu.dashboards":"Indicadores",
            "label.menu.management":"Gestão",
            "label.menu.marketAgents":"Agentes de Mercado",
            "label.menu.marketUnits":"Unidades Físicas",
            "label.menu.powerManagement":"Gestão de Energia",
            "label.menu.forecastTypes":"Tipos de Previsão",
            "label.menu.markets":"Mercados",
            "label.menu.home":"Início",
            "label.menu.purchasing":"Compra de Energia",
            "label.menu.memberArea":"Área de Membro",
            "label.menu.promotorArea":"Área do Promotor",
            "label.menu.egacArea":"Área da EGAC",
            "label.menu.myCommunities":"Minhas Comunidades",
            "label.menu.myData":"Meus Dados",
            "label.menu.myInvoices":"Minhas Faturas",
            "label.menu.myDocuments":"Meus Documentos",
            "label.menu.energyPrices":"Preços de Energia",
            "label.menu.energySales":"Venda de Energia",
            "label.menu.energyPurchases":"Compra de Energia",
            "label.menu.salesInvoices":"Venda de Energia",
            "label.menu.purchasesInvoices":"Compra de Energia",
            "label.menu.invoicingInformation":"Dados para Rec. / Pagamentos",
            "label.menu.egac":"Entidade Gestora (EGAC)",
            "label.menu.community":"Comunidade",
            "label.menu.receiptsPayments": "Recebimentos e Pagamentos",
            "label.menu.balance":"Conta Corrente",
            "label.menu.aggregation":"Agregação",
            "label.menu.aggregators":"Agregadores",
            "label.menu.producers":"Produtores",
            "label.menu.contracts":"Contratos",
            "label.menu.physicalUnits":"Instalações",
            "label.menu.invoicingClient":"Cliente de Faturação",
            "label.menu.agents":"Agentes Comerciais",
            "label.menu.contact":"Contacto",
            "label.menu.products":"Produtos e Serviços",
            "label.menu.physicalunit":"Instalação",
            "label.menu.receipts":"Recebimentos",
            "label.menu.agent":"Agente Comercial",
            "label.menu.saftFiles":"Ficheiros SAF-T",
            "label.menu.communications":"Comunicações",
            "label.menu.installationRegistration":"Registo da Instalação",
            "label.menu.viewTable":"Ver Tabela",
            "label.menu.viewChart":"Ver Gráfico",
            "label.menu.termsAndConditions":"Termos e Condições",
            // Page Titles
            "label.page.producerContracts":"Contratos do Produtor",
            "label.page.newContract":"Novo Contrato",
            "label.page.forecastTypes":"Tipos de Previsão",
            "label.page.viewForecastType":"Ver Tipo de Previsão",
            "label.page.addForecastTypeInstance":"Nova Previsão",
            // Labels Table Headers
            "label.table.header.period":"Período",
            "label.table.header.reference":"Referência",
            "label.table.header.drop":"DR/OP",
            "label.table.header.receivingDate":"Data de Recebimento",
            "label.table.header.startDate":"Início Horizonte Semanal",
            "label.table.header.endDate":"Fim Horizonte Semanal",
            "label.table.header.totalDeviationEnergy":"Total Desvio",
            "label.table.header.totalDeviationAmount":"Total Desvio (€)",
            "label.table.header.pdbf":"PDBF",
            "label.table.header.pdvd":"PDVD",
            "label.table.header.plannedEnergy":"Posição Final",
            "label.table.header.actualEnergy":"Quantidade Verificada",
            "label.table.header.deviationType":"Desvio",
            "label.table.header.deviationEnergy":"Quantidade Desvio",
            "label.table.header.receiptDate":"Data de Recebimento",
            "label.table.header.horizon":"Horizonte",
            "label.table.header.offerDate":"Data da Oferta",
            "label.table.header.offerCode":"Código Oferta",
            "label.table.header.offerUnit":"Unidade de Oferta",
            "label.table.header.totalOffer":"Total da Oferta (MWh)",
            "label.table.header.programme":"Programa",
            "label.table.header.requestDate":"Data do Pedido",
            "label.table.header.createdBy":"Criado Por",
            "label.table.header.actions":"",
            "label.table.header.referenceStartDate":"Início de Período",
            "label.table.header.referenceEndDate":"Fim de Período",
            "label.table.header.price":"Preço Energia (€/MW)",
            "label.table.header.physicalUnit":"Unidade Física",
            "label.table.header.forecastInstance":"Previsão",
            "label.table.header.regulatorRefID": "# DGEG",
            "label.table.header.companyName": "Empresa",
            "label.table.header.deliveryPoint": "CPE",
            "label.table.header.title": "Título",
            "label.table.header.billingPeriodStart": "Início Período de Faturação",
            "label.table.header.total": "Valor c/ IVA (€)",
            "label.table.header.status": "Estado",
            "label.table.header.step": "Passo",
            "label.table.header.description":"Descrição",
            "label.table.header.message":"Mensagem",
            "label.table.header.fiscalNumber":"NIF",
            "label.table.header.issueDate":"Data de Emissão",
            "label.table.header.cpe":"CPE",
            "label.table.header.invoiceNumber":"Fatura Nº",
            "label.table.header.dueDate":"Data de Vencimento",
            "label.table.header.name":"Nome",
            "label.table.header.createdOn":"Criado em",
            "label.table.header.contractActData":"Data Ativação",
            "label.table.header.contractEndData":"Data Fim",
            "label.table.header.cycle":"Ciclo",
            "label.table.header.documentNumber":"Documento",
            "label.table.header.documentType":"Tipo Documento",
            "label.table.header.details":"Detalhes",
            "label.table.header.month":"Mês",
            "label.table.header.surplus":"Excedente",
            "label.table.header.surplusEuro":"Excedente (€)",
            "label.table.header.corrections":"Correções (€)",
            "label.table.header.pending":"Pendente (€)",
            "label.table.header.paymentDate":"Data de Pagamento",
            "label.table.header.settlementDocument": "Nota de Pagamento",
            "label.table.header.pendingSettlement": "Pendente c/ IVA (€)",
            "label.table.header.omieReference":"Ref. OMIE",
            "label.table.header.orm":"Ref. REN",
            "label.table.header.processCode":"Código de Processo",
            "label.table.header.createDate":"Criado Em",
            "label.table.header.offeringUnit":"Unidade de Oferta",
            "label.table.header.maxPowerMW":"Potência Máxima (MW)",
            "label.table.header.maxSites":"Nº Máximo de Instâncias",
            "label.table.header.totalSites":"Total de Instâncias",
            "label.table.header.installationType":"Tipo de Instalação",
            "label.table.header.installedPowerkWh":"Potência Instalada (kW)",
            "label.table.header.aggregation":"É Agregação?",
            "label.table.rowsPerPage":"Linhas por Página",
            "label.table.header.dateTime":"Data / Hora",
            "label.table.header.marketHour":"Hora de Mercado",
            "label.table.header.marketSession":"Sessão de Mercado",
            "label.table.header.dcop":"DC/OP",
            "label.table.header.totalValue (€)":"Total",
            "label.table.header.magnitude":"Energia (MWh)",
            "label.table.header.date":"Data",
            "label.table.header.sessionName":"Programa",
            "label.table.header.forecastValue":"Previsão (MWh)",
            "label.table.header.programmingValue":"Programação (MWh)",
            "label.table.header.productionValue":"Produção (MWh)",
            "label.table.header.deviationsValue":"Desvios (MWh)",
            "label.table.market.session.MD":"Mercado Diário",
            "label.table.market.session.MI1":"Mercado Intra-Diário: Sessão 1",
            "label.table.market.session.MI2":"Mercado Intra-Diário: Sessão 2",
            "label.table.market.session.MI3":"Mercado Intra-Diário: Sessão 3",
            "label.table.market.session.MI4":"Mercado Intra-Diário: Sessão 4",
            "label.table.market.session.MI5":"Mercado Intra-Diário: Sessão 5",
            "label.table.market.session.MI6":"Mercado Intra-Diário: Sessão 6",
            "label.table.market.programme.pdbf":"PDBF",
            "label.table.market.programme.pdvd":"PDVD",
            "label.table.market.programme.pdbc":"PDBC",
            "label.table.market.programme.phf":"PHF",
            "label.table.market.programme.pibci":"PIBCI",
            // Labels KPIs
            "label.kpi.averageExcessPrice":"Preço Médio dos Desvios por Excesso",
            "label.kpi.averageDefectPrice":"Preço Médio dos Desvios por Defeito",
            "label.kpi.totalExcessQuantity": "Desvios por Excesso de Energia (MWh)",
            "label.kpi.totalDefectQuantity": "Desvios por Defeito de Energia (MWh)",
            "label.kpi.totalExcessSettlement": "Desvios por Excesso de Energia (€)",
            "label.kpi.totalDefectSettlement": "Desvios por Defeito de Energia (€)",
            "label.kpi.totalDeviationEnergy": "Saldo (MWh)",
            "label.kpi.totalDeviationSettlement": "Saldo (€)",
            "label.kpi.total": "Total",
            "label.kpi.salesTotal": "Total",
            "label.kpi.dueInvoices": "Faturas Vencidas",
            "label.kpi.expiredInvoices": "Faturas Expiradas",
            "label.kpi.salesSurplus": "Excedente",
            "label.kpi.salesCommunity": "Comunidade",
            "label.kpi.salesSelfConsumption": "Autoconsumo",
            "label.kpi.costsTotal": "Custos",
            "label.kpi.capacity" : "Potência",
            "label.kpi.internal" : "Rede Interna",
            "label.kpi.externalNetwork" : "Rede Externa",
            "label.kpi.prosumers" : "Produtores",
            "label.kpi.members" : "Membros",
            "label.kpi.storage" : "Armazenamento",
            "label.kpi.sharedEnergy" : "Energia Partilhada",
            "label.kpi.surplus": "Excedente",
            "label.kpi.networkCosts": "Acesso Redes",
            "label.kpi.managementCosts": "Custos de Gestão",
            "label.kpi.selfConsumption":"Autoconsumo",
            "label.kpi.community":"Comunidade",
            "label.kpi.supplier":"Comercializador",
            "label.kpi.totalConsumption":"Consumo Total",
            "label.kpi.totalProduction":"Produção Total",
            // I2 - Produção Total na UPAC
            "label.kpi.i2":"Produção Total",
            // I4 - Energia Partilhada pela IC
            "label.kpi.i4":"Energia Partilhada",
            // I8 - Excedente de Energia na IC
            "label.kpi.i8":"Excedente",
            // I9 - Consumo Fornecido à IC pelo Comercializador
            "label.kpi.i9":"Comercializador",
            // I10 - Autoconsumo na IC
            "label.kpi.i10":"Autoconsumo",
            // I11 - Autoconsumo através da RESP
            "label.kpi.i11":"Comunidade",
            // I13 - Faturação do Autoconsumo na IC
            "label.kpi.i13":"Autoconsumo (€)",
            // I14 - Faturação do Autoconsumo através da RESP na IC
            "label.kpi.i14":"Comunidade (€)",
            // I17 - Faturação de Energia Autoconsumida pela CE
            "label.kpi.i17":"Comunidade (€)",
            // I19 - Faturação do Excedente de Energia na CE
            "label.kpi.i19":"Excedente (€)",
            // I21 - Custos de Acesso à Rede
            "label.kpi.i21":"Custos com Acesso às Redes (€)",
            // I22 - Custos de Gestão da Comunidade
            "label.kpi.i22":"Custos com Gestão da Comunidade (€)",
            // I23 - Pagamento de Energia Vendida
            "label.kpi.i23":"Venda de Energia (€)",
            // I26 - Excedente de Energia na CE partilhada pela IC
            "label.kpi.i26":"Energia Partilhada através da RESP (MWh) Excedente",
            
            // Chart Labels
            "label.chart.networkAccessConsumption":"Custos com Acesso às Redes da Energia Consumida (€)",
            "label.chart.networkAccessShared":"Custos com Acesso às Redes da Energia Partilhada (€)",
            "label.chart.surplus":"Excedente (€)",
            "label.chart.sales":"Compra de Energia (€)",
            "label.chart.payments":"Venda de Energia (€)",
            "label.chart.receipts":"Recebimentos (€)",
            "label.chart.revenueCosts":"Faturação de Energia Partilhada e Custos (€)",
            "label.chart.title.programmingVsForecasts":"Comparação de Previsão e Programação (MWh)",
            "label.chart.title.receipts":"Recebimentos (€)",
            "label.chart.title.production":"Produção (MWh)",
            "label.chart.series.forecasts":"Previsão (MWh)",
            "label.chart.series.programming":"Programação (MWh)",
            "label.chart.series.prices":"Preço (€/MWh)",
            "label.chart.series.production":"Energia Produzia (MWh)",
            "label.chart.series.deviations":"Desvios (MWh)",
            // Navigation Labels
            "label.nav.home": "Início",
            "label.nav.consumptionInstallations": "Instalações de Consumo",
            // Fixed Options
            "label.option.upac": "UPAC",
            "label.option.upacia":"UPAC+IA",
            "label.option.ia": "IA",
            "label.option.ip": "IP",
            // Deviation Types
            "label.deviation.defect":"Defeito",
            "label.deviation.excess":"Excesso",
            "label.drop.payment":"Pagamento",
            "label.drop.receipt":"Recebimento",
            // Document Void Stepper
            "label.step.confirmDocument":"Confirmar Documento a Anular",
            "label.step.warningConfirmation":"Confirme que este é o documento a anular, depois de realizada esta ação não será possível voltar atrás. Será criada uma nota de crédito que anula este documento na totalidade.",
            // Document Create Stepper
            "label.step.confirmDocumentToCreate":"Confirmar Dados Documento",
            "label.step.confirmDocumentDates":"Confirmar Datas do Documento",
            // Registration Form
            "label.step.termsAndConditions":"Termos e Condições",
            "label.step.userRegistration":"Dados de Registo",
            "label.step.userRegistrationComplete":"Registo Completo",
            "label.step.chooseReport":"Escolher o Relatório",
            "label.step.configureReport":"Configurar o Relatório",
            "label.step.confirmReport":"Confirmar o Relatório",
            // Success Messages
            "success.api.create": "Registo criado com sucesso!",
            "success.api.save": "Registo atualizado com sucesso!",
            "api.success.save": "Registo atualizado com sucesso!",
            "success.message.foundProgramme":"Programa já existente foi encontrado.",
            "success.message.programProcessed":"Programa processado e informação atualizada.",
            "success.message.passwordResetEmail":"Foi enviado um email com as instruções para reset de password.",
            "success.message.registrationEmail":"Foi enviado um email com as instruções para registo de utilizador.",
            "success.message.offerCreated":"Oferta criada com sucesso.",
            "success.message.marketSessionInfoLoaded":"Carregada informação de sessões de mercado.",
            // Error Messages
            "error.api.marketSessionLoadingError": "Não foi possível carregar informação de sessões de mercado.",
            "error.pages.notFound":"A página que procura não foi encontrada!",
            "error.forms.errors": "Existem erros no formulário. Corrija-os e volte a tentar!",
            "error.forms.mandatory": "Este campo é obrigatório!",
            "error.forms.numeric": "Este campo é numérico!",
            "error.forms.positiveNumber": "Este campo deve ser um número positivo!",
            "error.forms.emailFormat": "Formato de Email inválido!",
            "error.api.save": "Ocorreu um erro ao gravar este registo!",
            "error.api.loadingList": "Ocorreu um erro ao carregar a lista!",
            "error.api.generic": "Ocorreu um erro na comunicação com o servidor.",
            "error.forms.contracts.deliveryPointContractExists":"Já existe um contrato ativo para o CPE/Produto selecionados e não é possível ter dois contratos ativos. Corrija o CPE ou o Produto.",
            "error.forms.contracts.minActDate":"Data de Ativação tem de ser posterior à data de assinatura.",
            "error.form.findocs.notProcessed" : "Não foi possível processar os documentos.",
            "error.form.positiveNumber":"Deve ser um número superior a 0",
            "error.forms.installationTypeRequired":"Tipo de Instalação é de preenchimento obrigatório.",
            "error.forms.aggregationTypeRequired":"Indicação de Agregação é de preenchimento obrigatório.",
            "error.forms.datesMandatory":"As datas de Início e fim são de preenchimento obrigatório.",
            "error.forms.maxPeriodTwelveMonths":"O período máximo de pesquisa são 12 meses.",
            "error.forms.endDateSuperiorStartDate":"A Data Fim deve ser superior à Data de Início.",
            "error.forms.maxPeriodOneMonth":"O período máximo de pesquisa é de 1 mês.",
            "error.api.contract.productionDeliveryPointExists":"O CPE de Produção já se encontra associado a outra instalação.",
            "error.api.contract.consumptionDeliveryPointExists":"O CPE de Consumo já se encontra associado a outra instalação.",
            "error.api.mandatoryClosingDate":"Data de Fecho é de preenchimento obrigatório.",
            "error.api.closingDateInFuture":"Data de Fecho deve ser menor ou igual à data atual.",
            "error.forms.marketagent.passwordIdentical":"As passwords têm de ser idênticas.",
            "error.api.contract.physicalUnitAlreadyAtUse":"Unidade Física já se encontra associada a outra Instalação.",
            "error.forms.findocs.noContractInfo":"Sem dados de contrato. A redirecionar para a página de contrato.",
            "error.forms.dateInFuture":"Esta data não pode estar no futuro",
            "error.forms.register.fiscalNumberNotFound":"Dados de Registo Inválidos.",
            "error.forms.register.tokenFiscalNumberCombinationNotValid":"Autorização de registo expirada ou inválida.",
            "errors.forms.minimumLengthTen":"A password deve ter um minimo de 10 caracteres.",
            "error.forms.invalidIBAN":"IBAN inválido. Verifique pff que inseriu corretamente o IBAN.",
            "error.forms.invalidCPE":"CPE inválido.",
            "errors.forms.passwordComplexity":"A password deve conter pelo menos: uma letra minuscula/maiscula, um digito e um caracter especial (!@#\$%^&*_). ",
            "error.api.loadingContract":"Erro a carregar os dados do contrato. Atualize a página e tente novamente.",
            "error.forms.onlyOneItemcanBeDefault":"Só pode escolher uma entrada por omissão!",
            "error.forms.mustHaveOneDefault":"Tem de escolher uma entrada por omissão!",
            "error.api.trading.physicalUnitNameExists":"Já existe uma unidade fisíca com o mesmo nome.",
            "error.api.trading.offerAlreadyExists":"Oferta já existente para a Unidade de Oferta selecionada.",
            "error.api.trading.offerTotalValueZero":"Para submeter em mercado, o valor total da oferta não pode ser 0.",
            "error.api.trading.offerSessionAlreadyClosed":"Sessão de mercado já fechada. Não é possível submeter a oferta.",
            "error.api.trading.phfNotAvailable":"Resultados de Programa ainda não estão disponíveis, não é possível criar a oferta.",
            "error.api.fiscalNumberNotFound":"NIF não identificado. Contacte o seu Agente de Mercado.",
            "error.api.registrationProcessNotStarted":"Processo de Registo não concluído para o NIF em causa. Solicite uma nova Chave de Registo.",
            "error.api.userAlreadyRegistered":"Processo de Registo já realizado para este NIF. Avance para o Login ou Pedido de nova Senha",
            "error.api.tokenExpired":"Chave de Registo expirada. Utilize o link para Pedir Email de Registo.",
            "error.api.unexpectedError":"Ocorreu um erro inesperado. Contacte o suporte da Plataforma.",
            "error.api.registrationNotComplete":"Processo de Registo não concluído para o NIF em causa. Solicite uma nova Chave de Registo.",
            "error.api.loginNotAuthorized":"Utilizador não registado ou combinação utilizador/password inválida.",
            "error.api.loginUnknown":"Ocorreu um erro desconhecido ao realizar o início de sessão. Contacte pff o nosso Suporte.",
            "error.api.fiscalNumbernoContracts":"Não foi encontrado nenhum contrato associado ao NIF. Contacte o seu agente de mercado.",
            "error.api.contractHasNoReceiptEntity":"É obrigatório definir uma entidade de recebimentos para o contrato.",
            "error.api.receiptEntityNoTaxRegime":"É obrigatório selecionar um Regime de Impostos para a entidade de recebimentos.",
            "error.api.contractTermsAfterClosingDate":"A data de fecho tem de ser superior ao ínicio dos últimos termos contratuais.",
            "error.api.contractFinancialDocsAfterClosingDate":"A data de fecho tem de ser superior ao último período faturado.",
            "error.api.activationDateBeforeSignDate":"Data de ativação tem de ser superior à data de assinatura.",
            "error.api.cannotUpdateActDateHasRenewal":"Não é possível alterar a data de ativação pois o contrato já foi renovado.",
            "error.api.contractTermsStartBeforeContractStart":"Data de ínicio não pode ser inferior à data de ativação do contrato.",
            "error.api.moreThanOneActiveTermsInPeriod":"Não é possível alterar termos e condições que já foram renovados",
            "error.api.trading.noDeliveryPointsFoundPhysicalUnit":"Não existem pontos de entrega associados à unidade física.",
            "error.api.financialDocumentsAfterClosingDate":"Existem documentos financeiros emitidos após a data de encerramento pretendida.",
            "error.api.termsExistAfterStartDate":"Termos existem após a data selecionada.",
            "error.api.cannotHaveGapBetweenTerms":"Existe um período de tempo sem termos contratuais entre os existentes e os novos. Por favor corrija este erro.",
            "error.api.b021p.missingCae":"É obigatório preencher o CAE do Produtor ou da Unidade Fisíca.",
            "error.api.b021p.missingConnectionPowerInformation":"É obrigatório preencher a informação de potência de ligação.",
            "error.api.b021p.missingInstalledPowerInformation":"É obrigatório preencher a informação de potência instalada.",
            "error.api.b021p.missingProductionType":"É obrigatório preencher o tipo de produção.",
            "error.api.b021p.missingTechnologyType":"É obrigatório preencher o tipo de tecnologia.",
            "error.api.b021p.producerMissingCompleteAddressInformation":"É obrigatório preencher a informação completa de morada do produtor.",
            "error.api.b021p.missingContractActDate":"É obrigatório preencher a data de ativação pretendida para o contrato.",
            "error.api.b021p.missingAggregatorDsoId":"Falta preencher o código de referência do agregador.",
            "error.api.trading.communicationErrorWithOMIE":"Ocorreu um erro na comunicação com o OMIE. Contacte o suporte ou volte a tentar mais tarde.",
            "error.api.trading.noOffersFound":"Não foram encontradas ofertas para a Unidade de Oferta selecionada ou estas não se encontram no estado que permite a pesquisa de programa.",
            "error.api.trading.offerNotLatestVersion":"A oferta selecionada não é a última versão. Por favor atualize a página e tente novamente.",

            // Contract Validation Messages
            "error.api.contract.activate.contractNotInPreStatus":"O contrato já não se encontra em estado de Pré-Registo ou Em Ativação.",
            "error.api.contract.activate.activationDateNotReached":"Data de Ativação ainda não foi atingida.",
            "error.api.contract.activate.physicalUnitNotFound":"Unidade Física não encontrada.",
            "error.api.contract.activate.connectionPowerNotFound":"A Potência de Ligação na Unidade Física ainda não foi preenchida.",
            "error.api.contract.activate.connectionGreater1MWAndNoMeterId":"Potência de Ligação superior ou igual a 1MW e sem identificação GGS.",
            "error.api.contract.activate.receiptEntityNotFound":"Cliente de Faturação não encontrado.",
            "error.api.contract.activate.noContractTerms":"Não existem termos contratuais para o contrato.",
            // Axios Errors
            "Request failed with status code 500":"Ocorreu um erro na comunicação com o servidor. Contacte o suporte.",

            // Messages
            "message.forms.noFiles" : "Não foi selecionado nenhum ficheiro!",
            "message.forms.files" : "Ficheiros Selecionados:",
            "message.form.contracts.lodadedDeliveryPoint" : "Informação da Unidade Física carregada automaticamente.",
            "message.form.contracts.loadedProducerData":"Informação carregada a partir do Produtor.",
            "message.form.contracts.entityData":"Entidade já existe, dados carregados a partir da entidade.",
            "message.form.editModeActive": "Modo de Edição ativo",
            "message.form.recordSaved": "Alterações gravadas!",
            "message.form.recordCreated": "Alterações gravadas!",
            "message.form.findocs.processed" : "Documentos Processados e enviados para o ERP",
            "message.table.noData":"Ainda não realizou qualquer consulta ou a consulta realizada não devolveu resultados. Altere os parâmetros de pesquisa e procure novamente.",
            "message.info.noContracts":"Não tem nenhum contrato associado.",
            "message.info.noProgrammeFound":"Não foi encontrado nenhum programa para o período selecionado.",
            "message.info.dataLoaded":"Dados carregados com sucesso.",
            "message.form.login.sucess":"Login Realizado com Sucesso",
            "message.forms.fileSizeExceeded":"Tamanho do ficheiro excede o limite permitido de 10MB.",
            // Backend Options
            "Quarterly": "Trimestral",
            "Monthly": "Mensal",
            "Monthly (15th)": "Mensal / Meio do Mês",
            "Monthly (2nd)": "Mensal / Início do Mês",
            "No Billing Period":"Sem Ciclo / Ato Único",
            "Weekly":"Semanal",
            "Yearly":"Anual",
            "Validation": "Em Validação",
            "Approved":"Aprovada",
            "Prospecting":"Construção",
            "Operating":"Em Operação",
            "label.status.receipt.DUE": "Por Pagar",
            "label.status.report.PENDING":"Pendente",
            "label.status.report.COMPLETED":"Concluído",
            "label.status.report.ERROR":"Com Erro",
            "label.status.report.RUNNING":"Em Curso",
            "label.status.offer.UNSENT":"Não Enviada",
            "label.status.offer.SENT":"Enviada",
            "label.status.offer.SUBMITTED":"Submetida",
            "label.status.offer.VOIDED":"Anulada",
            "label.status.offer.REJECTED":"Recusada",
            "label.status.offer.REFUSED":"Recusada",
            "label.status.offer.UPDATED":"Pendente",
            "label.status.offer.PENDING":"Pendente",
            "label.status.offer.ARCHIVED":"Arquivada",
            "label.status.contract.ATI":"Ativo",
            "label.status.contract.PRE":"Pré-Registo",
            "label.status.contract.PEN":"Pendente",
            "label.status.contract.REC":"Recusado",
            "label.status.contract.FEC":"Fechado",
            "label.type.offer.C":"Compra",
            "label.type.offer.V":"Venda",
            // Invoices Status
            "Paid":"Liquidada",
            "Paid Off":"Liquidada",
            "Pending":"Pendente",
            "Void":"Anulada",
            "Processed":"Processada",
            "Issued":"Emitida",
            "Unpaid":"Por Pagar",
            // Producer Status
            "Pre-Record":"Pré-Registo",
            "Active":"Ativo",
            "Inactive":"Inativo",
            "Refused":"Recusado",
            "Pending Contract":"Pendente",
            "No Contracts":"Sem Contrato",
            "Activating":"Em Ativação",
            // Financial Document Types
            "Auto Invoice":"Autofatura",
            "Purchase Document":"Documento de Compra",
            "Sales Document":"Documento de Venda",
            "Credit Note":"Nota de Crédito",
            // Payment TErms
            "20 Days":"20 Dias",
            "30 Days":"30 Dias",
            "60 Days":"60 Dias",
            // Long Text Messages
            "label.message.producerExists": "Um produtor com o NIF inserido já existe e não é possível criar dois registos de produtor para o mesmo NIF. Deseja inserir outro NIF ou ser redirecionado para a página do produtor existente?",
            "label.message.goBackConfirmation": "Ao voltar atrás irá perder todas as alterações realizadas, este registo não foi gravado!",
            "label.message.recordsProcessedSuccess":"Registos processados com sucesso.",

            "label.contract.action.REOPEN":"Reabertura",
            "label.contract.action.RENEW":"Renovação",
            "label.contract.action.CLOSE":"Cessação",
            "label.contract.action.CREATE":"Criação",
            "label.contract.action.UPDATE":"Atualização",
            "label.contract.action.UPDATE_ACTDATE":"Alterar Assinatura / Cessação Contrato",
            "label.contract.action.ACTION_UPD_TERMS":"Atualização Termos",
            "label.contract.action.SCHED_CLOSE":"Agendamento Cessação",
        }
    }
};
i18next.use(initReactI18next).init({
    resources,
    lng: "pt",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});
