import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const { t } = useTranslation();

    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    const [user, setUser] = useState(() =>
        localStorage.getItem("user")
            ? jwt_decode(localStorage.getItem("user"))
            : null
    );

    const [profile, setProfile] = useState(() => {
        localStorage.getItem("profile")
    });

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const login = async (username, password) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_API_PATH}/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password
            })
        }).catch((e) => {
            console.error("caught error fetching user profile, returning to login page.");
            logout();
            navigate("/login");
        });
        const data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            let tokens = JSON.stringify(data);
            localStorage.setItem("authTokens", tokens);
            getProfile(data.access);
        } else if (response.status === 401) {
            console.error("Got response status: " + response.status);
            throw new Error(t("error.api.loginNotAuthorized"));
        } else {
            console.error("Got response status: " + response.status);
            throw new Error(t("error.api.loginUnknown"));
        }
    };

    const register = async (username, password, password2) => {
        const response = await fetch("http://127.0.0.1:8000/api/register/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password,
                password2
            })
        });
        if (response.status === 201) {
            navigate("/login");
        } else {
            alert("Something went wrong!");
        }
    };

    const logout = () => {
        setAuthTokens(null);
        setUser(null);
        setProfile(null);
        localStorage.removeItem("authTokens");
        navigate("/");
    };

    const getProfile = async (access) => {
        try {
            console.log("fetching user profile...")
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_API_PATH}/auth/user/`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${access}`,
                        "Content-Type": "application/json",
                    }
                }
            ).catch((e) => {
                console.error("caught error fetching user profile, returning to login page.");
                logout();
                navigate("/login");
            }); 

            let responseData =  await response.json();
            let userProfile = responseData[0];
            if (userProfile) {
                let perms = [];
                userProfile.groups.map((group) => (perms = [...perms, ...group.permissions]));
                perms = [...perms, ...userProfile.user_permissions];
                userProfile["permissions"] = perms;
                setProfile(userProfile);
                localStorage.setItem("profile", userProfile);
            }
            setUser(userProfile);
            
        } catch (e) {
            console.error(e);
        }        
    };

    const _validatePermissionGroup = (groupName) => {
        if(groupName.startsWith("!")) {
            return !profile?.groups?.some( (group) => group.name === groupName.substring(1));
        } else {
            return profile?.groups?.some( (group) => group.name === groupName);
        }
    }
    const hasPermissionGroup = (groups) => {
        if(groups instanceof Array) {
            return groups.some((el) => _validatePermissionGroup(el))
        } else {
            return _validatePermissionGroup(groups);
        }
    }

    const contextData = {
        user,
        setUser,
        authTokens,
        profile,
        setAuthTokens,
        register,
        login,
        logout,
        getProfile,
        hasPermissionGroup,
    };

    useEffect(() => {
        if (authTokens) {
            setUser(jwt_decode(authTokens.access));
            if(profile == null) {
                getProfile(authTokens.access).catch((e) => {
                    console.error("caught error fetching user profile, returning to login page.");
                    logout();
                    navigate("/login");
                });
            }
        }
        setLoading(false);
    }, [authTokens]);

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};