import useAxios from "utils/useAxios";

const BASE_URL = '/document-links/';
const DOCUMENT_TYPES_BASE_URL = '/core/documents/types/'

export const useDocumentsAPI = () => {
    const {api, cachedRequest} = useAxios();

    let makeGetApiCall = (url, callBack, errorCallBack) => {
        api.get(url).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        });
    };

    let fetchURLById = (id, callBack, errorCallBack) => {
        if (!id || id == null) {
            return;
        }
        makeGetApiCall(`${BASE_URL}${id}/url/`, callBack, errorCallBack);
    }

    let types = (owner, callBack, errorCallBack) => {
        let filters = owner ? `?owner=${owner}` : "";
        cachedRequest(`document-types-${owner}`, DOCUMENT_TYPES_BASE_URL + filters, callBack);
    }

    return {
        fetchURLById : fetchURLById,
        types: types,
    }
};