import {
    Box,
    Grid,
    IconButton,
    styled,
    useMediaQuery,
} from "@mui/material";
import { SettingsContext } from "contexts/settingsContext";
import { Fragment, useContext } from "react";
import LanguagePopover from "./popovers/LanguagePopover";
import ThemeIcon from "icons/ThemeIcon";
import useAuth from "hooks/useAuth";
import { H6 } from "components/Typography";

// ------------------------------------------------

// ------------------------------------------------
// custom styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));
const ToggleIcon = styled(Box)(({ theme, width }) => ({
    height: 3,
    margin: "5px",
    marginLeft: 0,
    width: width || 25,
    borderRadius: "10px",
    transition: "width 0.3s",
    backgroundColor: theme.palette.primary.main,
}));

const SideBarProfile = (props) => {
    const { setShowMobileSideBar, isCompact } = props;
    const { settings, saveSettings } = useContext(SettingsContext);
    const { logout, profile } = useAuth();
    const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200));

    const handleChangeTheme = (value) => {
        saveSettings({ ...settings, theme: value });
    };

    if(!profile) {
        return(
            ""
        );
    }
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            position="sticky">
            <Grid item xs={12}>
                <H6>{`${profile.first_name} ${isCompact === "true" ? "" : profile.last_name}`}</H6>
            </Grid>
            <Grid item xs={12}>
            {downMd && (
                    <Box
                        sx={{
                            cursor: "pointer",
                        }}
                        onClick={setShowMobileSideBar}
                    >
                        <ToggleIcon />
                        <ToggleIcon width={18} />
                        <ToggleIcon width={9} />
                    </Box>
                )}

                <Fragment>
                    {settings.theme === "light" ? (
                        <StyledIconButton onClick={() => handleChangeTheme("dark")}>
                            <ThemeIcon />
                        </StyledIconButton>
                        ) : (
                        <StyledIconButton onClick={() => handleChangeTheme("light")}>
                            <ThemeIcon />
                        </StyledIconButton>
                        )
                    }
                        <LanguagePopover />
                </Fragment>
            </Grid>
        </Grid>

    );
};

export default SideBarProfile;
