import { DRF_DATE_FORMAT } from '../constants';
import { useDocumentsAPI } from 'service/DocumentsAPIService';

export const searchByName = (listData, searchValue) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item.name.toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};
export const getRoute = (pathname) => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};

export const formatNumber = (value, decimalCases) => {
    let _decimalCases = decimalCases || 2;
    let num = Number(value);
    return num.toFixed(_decimalCases);
};

export const formatNumberString = (originalValue = 0, decimalCases = 2) => {
  /**
   * truncates any string representing a number down to two decimal cases.
   * The standard methods of nodeJS perform roundings that cannot be accepted
   * in the platform (E.g. toFixed, toPrecision).
   */
  if(originalValue === null || originalValue === undefined) {
    originalValue = 0.00;
  }

  let matches = originalValue.toString().match("^-?\\d+(?:\\.\\d{0," + +decimalCases + "})?");
  
  try {
    let match = matches[0];
    return match;
  } catch (e) {
    console.warn("failed to match for " + originalValue.toString());
    console.error(e);
    return "0.00";
  }
}

export const useOpenDocument = () => {
  const documentsApi = useDocumentsAPI();

  const open = (id) => {
    documentsApi.fetchURLById(id, (response) => {
        //window.open(response.data, '_blank', 'noopener,noreferrer');
        var a = document.createElement('A');
        a.target="_blank";
        a.href = response.data;
        a.download = response.data.substr(response.data.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }, (error) => {
        console.error(error);
    });
  }

  return {
    open: open
  };
  
}

export const validateFiscalNumber = (nif) => {
  const validationSets = {
      one: ['1', '2', '3', '5', '6', '8'],
      two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
  };
  if (!nif || nif.length !== 9) return false;
  if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) return false;
  const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
  const modulo11 = (Number(total) % 11);
  const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
  return checkDigit === Number(nif[8]);
}

export const prepareDateForSubmit = (date) => {
  if (date === null || date === undefined) {
    return null;
  }
  return date.format(DRF_DATE_FORMAT);
}

/**
 * 
 * @param {*} tabIndex
 * @returns 
 */
export const getCurrentTab = (levels, level, _default) => {

    let path = window.location.pathname;
    // remove the last slash if it exists
    if (path.endsWith("/")) {
        path = path.slice(0, -1);
    }
    const pathParts = path.split("/");
    try {
        const lastPath = pathParts[pathParts.length - levels + level];    
        return lastPath;
    } catch (e) {
        return _default;
    }

}