import CommonFooterComponents from "layouts/CommonFooterComponents";
import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

export const UserFeedbackContext = createContext({});

export const UserFeedbackContextProvider = ({children}) => {

    const { t } = useTranslation();
    const [isOpenSuccessMessage, setIsOpenSuccessMessage] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("success.api.save");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [severity, setSeverity] = useState("info");
    const [alertMessage, setAlertMessage] = useState(null);
    const [openAlert, setOpenAlert]  = useState(false);

    const setAndShowErrorMessage = (message) => {
        if(!message) {
            setOpenBackdrop(false);
            return;
        }
        if(message.response && message.response.data && message.response.data.detail) {
            setErrorMessage(t(message.response.data.detail));
        } else if(message.response && message.response.detail) {
            setErrorMessage(t(message.response.detail));
        } else if(message.response && message.response.data && message.response.data.error) {
            setErrorMessage(t(message.response.data.error));
        } else {
            setErrorMessage(t(message));
        }
        
        setIsOpenErrorMessage(true);
        setOpenBackdrop(false);
    }

    const setAndShowSuccessMessage = (message) => {
        setSuccessMessage(t(message));
        setIsOpenSuccessMessage(true);
        setOpenBackdrop(false);
    }

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpenSuccessMessage(false);
        setIsOpenErrorMessage(false);
    };

    const feedbackContext = {setAndShowErrorMessage, setAndShowSuccessMessage, setOpenBackdrop, setSeverity, setAlertMessage, setOpenAlert};

    return (
        <UserFeedbackContext.Provider value={feedbackContext}>
            <Outlet />
            {children}
            <CommonFooterComponents
                handleCloseAlert={handleClose}
                handleCloseBackdrop={handleCloseBackdrop}
                successMessage={successMessage}
                errorMessage={errorMessage}
                showSuccessMessage={isOpenSuccessMessage}
                showErrorMessage={isOpenErrorMessage}
                showBackDrop={openBackdrop} />
        </UserFeedbackContext.Provider>
    )
}