import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import useAuth from "hooks/useAuth";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import Login from "pages/authentication/login";
import { Fragment, useState } from "react";
import { Outlet } from "react-router";
import DashboardSidebar from "./DashboardSidebar";
import DashboardHeader from "layouts/layout-parts/DashboardHeader";

const DashboardLayout = ({ children }) => {

    const auth = useAuth();
    const [sidebarCompact, setSidebarCompact] = useState(false);
    const [showMobileSideBar, setShowMobileSideBar] = useState(false);

    const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);

    const handleMobileDrawerToggle = () =>
        setShowMobileSideBar((state) => !state); // dashboard body wrapper custom style

    const customStyle = {
        width: `calc(100% - ${sidebarCompact ? "0x" : "280px"})`,
        marginLeft: sidebarCompact ? "0px" : "280px",
    };

    if (!auth?.user) {
        return (
            <Login />
        );
    }

    return (
        <Fragment>
            <DashboardSidebar
                sidebarCompact={sidebarCompact}
                showMobileSideBar={showMobileSideBar}
                setSidebarCompact={handleCompactToggle}
                setShowMobileSideBar={handleMobileDrawerToggle}
            />

            <LayoutBodyWrapper sx={customStyle}>
                <Stack spacing={0}>
                    <DashboardHeader
                        setShowSideBar={handleCompactToggle}
                        setShowMobileSideBar={handleMobileDrawerToggle}
                    />

                    <Box pb={4}>
                        {children || <Outlet />}
                    </Box>
                </Stack>
            </LayoutBodyWrapper>
        </Fragment>
    );
};

export default DashboardLayout;
