import { AccountBalance, AdminPanelSettingsTwoTone, BalanceTwoTone, ElectricMeterTwoTone, FileOpenTwoTone, HouseTwoTone, LocationCityTwoTone, LogoutTwoTone, OfflineBoltTwoTone, PersonTwoTone, ReceiptTwoTone, ReportRounded, ReportTwoTone, SettingsPowerTwoTone } from "@mui/icons-material";

export const navigations = [
    {
        name: "Logout",
        icon: LogoutTwoTone,
        path: "/logout",
        auth: "all",
    },
    {
        name: "label.menu.home",
        icon: HouseTwoTone,
        path: "/home",
        auth: "all",
    },
    {
        name: "label.menu.myData",
        path: "/mydata",
        auth: "view_member_area",
        icon: PersonTwoTone,
    },
    {
        name: "label.menu.saftFiles",
        path: "/saft",
        auth: "view_member_area",
        icon: AccountBalance,
    },
    {
        name: "label.menu.termsAndConditions",
        path: "/terms",
        auth: "view_member_area",
        icon: ReceiptTwoTone,
    },
    {
        name: "Energy Communities",
        icon: LocationCityTwoTone,
        auth: "view_mstcommunities;view_mstmembers",
        children : [
            {
                name: "Communities",
                path: "/communities",
                auth: "view_mstcommunities", 
            },
            {
                name: "Members",
                path: "/members/",
                auth: "view_mstmembers",
            },
        ]
    },
    {
        name: "label.menu.aggregation",
        icon: OfflineBoltTwoTone,
        auth: "view_producers",
        children : [
            {
                name: "label.menu.marketAgents",
                path: "/agents/",
                auth: "view_aggregators",
            },
            {
                name: "label.menu.dailyMarkets",
                path: "/markets/dayahead/submit/",
                auth: "view_tradingmarketsessions",
            },
            {
                name: "label.menu.intraDayMarkets",
                path: "/markets/intraday/submit/",
                auth: "view_tradingmarketsessions",
            },
            {
                name: "label.menu.settlements",
                path: "/markets/settlements/energy/",
                auth: "view_tradingmarketsessions",
            },
            {
                name: "label.menu.marketUnits",
                path: "/market-units/",
                auth: "view_tradingofferingunits", 
            },
            {
                name: "label.menu.producers",
                path: "/producers/",
                auth: "view_producers", 
            },
            {
                name: "label.menu.invoicingClient",
                path: "/clients/",
                auth: "view_producers", 
            },
            {
                name: "label.menu.contracts",
                path: "/contracts/",
                auth: "view_contracts", 
            },
            {
                name: "label.menu.powerTrading",
                path: "/power-trading/",
                auth: "view_producers", 
            },
            {
                name: "label.menu.dataUpload",
                path: "/data/",
                auth: "view_energyfileprocessingrequest", 
            },
        ]
    },
    {
        name: "label.menu.powerManagement",
        icon: SettingsPowerTwoTone,
        auth: "view_forecasttypes",
        children :[
            {
                name: "label.menu.forecastTypes",
                path: "/energy-management/forecasts/types/",
                auth: "view_forecasttypes",
            }
        ]
    },
    {
        name: "Installations",
        icon: ElectricMeterTwoTone,
        auth: "view_astmeters",
        children: [
            {
                name: "Consumption Instalations",
                path: "/consumption-installations/",
                auth: "view_astmeters",
            },
            {
                name: "Production Units",
                path: "/production-units/",
                auth: "view_astproductionunits",
            },
            {
                name: "Storage Installations",
                path: "/storage-installatons/",
                auth: "view_astmeters",
            },
        ]
    },
    {
        name: "Financial",
        icon: BalanceTwoTone,
        auth : "view_slsinvoices",
        children : [
            {
                name: "Invoices",
                path: "/invoices/",
                auth : "view_slsinvoices",
            }
        ]
    },
    {
        name: "Administration",
        icon: AdminPanelSettingsTwoTone,
        auth: "is_superuser",
        children : [
            {
                name: "label.title.entities",
                path: "/entities/",
                auth: "view_mstentities",
            },
        ],
    },
    {
        name: "Reports",
        icon: FileOpenTwoTone,
        auth: "view_reports",
        path: "/reports/",
    }
];